import { ILicenseModel } from 'tno-core';

export const defaultLicense: ILicenseModel = {
  id: 0,
  name: '',
  description: '',
  isEnabled: true,
  sortOrder: 0,
  ttl: 0,
};
