import styled from 'styled-components';

export const ReportFormScheduler = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 1rem;
  }
`;
