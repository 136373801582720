import styled from 'styled-components';

export const ProductSubscribersForm = styled.div`
  .approval-actions {
    padding: unset;
    > button {
      height: unset;
    }
  }
`;
